var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex justify-content-between"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('h4',{staticClass:"mr-2"},[_vm._v("Inventory Summary")])]),_c('div',{staticClass:"d-flex align-items-center"},[_c('b-button',{staticClass:"mr-2 my-1",attrs:{"variant":"dark","to":{ path: '/inventory/create' }}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon","size":"16"}}),_vm._v(" Add a New Product ")],1),_c('b-button',{directives:[{name:"b-modal",rawName:"v-b-modal.record-stock-modal",modifiers:{"record-stock-modal":true}}],attrs:{"variant":"primary"}},[_c('feather-icon',{attrs:{"icon":"EditIcon","size":"16"}}),_vm._v(" Record Stock ")],1)],1)]),_c('b-row',[_c('b-col',{attrs:{"cols":"6"}},[_c('b-card',{attrs:{"bg-variant":"primary","text-variant":"white"}},[_c('b-row',[_c('b-col',[_c('h5',{staticClass:"text-white"},[_vm._v("All Products")])]),_c('b-col',[_c('h5',{staticClass:"text-white"},[_vm._v("Lowstock alert")])])],1),_c('b-row',{staticClass:"mt-2"},[_c('b-col',[_c('p',[_c('strong',[_vm._v(_vm._s(_vm.totalProducts))])])]),_c('b-col',[_c('p',[_c('b-link',{directives:[{name:"b-modal",rawName:"v-b-modal.low-stock-modal",modifiers:{"low-stock-modal":true}}],staticClass:"text-decoration-underline text-white",staticStyle:{"text-decoration":"underline"}},[_c('strong',[_vm._v(_vm._s(_vm.lowStockProducts.length))])])],1)])],1)],1)],1),_c('b-col',{attrs:{"cols":"6"}})],1),_c('b-card',[_c('MyTable',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"isLoading":_vm.isLoading,"checkboxEnabled":true,"inventory":true},on:{"deleteItems":_vm.deleteItems},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var props = ref.props;
return [(props.column.field === 'image')?_c('div',[(
              props.row.product_images && props.row.product_images.length > 0
            )?_c('div',[_c('img',{staticClass:"img-thumbnail",staticStyle:{"width":"160px","height":"130px"},attrs:{"src":props.row.product_images[0].image_path,"alt":"Product Image"}})]):_c('div',[_vm._v("No image available")])]):(props.column.field === 'sellingPrice')?_c('div',[_c('span',[_vm._v(_vm._s(_vm.$helpers.formatTotal(props.row.selling_price)))])]):(props.column.field === 'totalValue')?_c('div',[_c('span',[_vm._v(_vm._s(_vm.$helpers.formatTotal(props.row.total_value)))])]):(props.column.field === 'action')?_c('div',{staticClass:"d-flex justify-content-center"},[_c('b-dropdown',{attrs:{"variant":"link","toggle-class":"text-decoration-none","no-caret":""},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"text-body align-middle mr-25",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[_c('b-dropdown-item',{attrs:{"to":{
                name: 'inventory-product-detail',
                params: { id: props.row.id },
              }}},[_c('span',[_vm._v("Details")])]),_c('b-dropdown-item',{attrs:{"to":{
                name: 'inventory-edit-product',
                params: { id: props.row.id },
              }}},[_c('span',[_vm._v("Edit")])]),_c('b-dropdown-item',{on:{"click":function($event){return _vm.deleteItem(props.row)}}},[_c('span',[_vm._v("Delete")])])],1)],1):_vm._e()]}}])})],1),_c('b-modal',{attrs:{"id":"record-stock-modal","hide-header":"","hide-footer":"","no-close-on-backdrop":""}},[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('validation-provider',{attrs:{"name":"Transaction Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Transaction Type"}},[_c('b-form-radio-group',{staticClass:"w-100",attrs:{"name":"type","buttons":"","button-variant":"primary"},model:{value:(_vm.type),callback:function ($$v) {_vm.type=$$v},expression:"type"}},[_c('b-form-radio',{staticClass:"w-50",attrs:{"value":"1"}},[_vm._v("In")]),_c('b-form-radio',{staticClass:"w-50",attrs:{"value":"0"}},[_vm._v("Out")])],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"Date Added","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Date Added"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"placeholder":"Select Date"},model:{value:(_vm.date_added),callback:function ($$v) {_vm.date_added=$$v},expression:"date_added"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),(_vm.type === '0')?_c('validation-provider',{attrs:{"name":"Order ID","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Order ID"}},[_c('v-select',{attrs:{"options":_vm.options,"filterable":false,"reduce":function (order) { return order.id; },"placeholder":"Enter Order ID","label":"id"},on:{"search":_vm.onSearch},model:{value:(_vm.order_id),callback:function ($$v) {_vm.order_id=$$v},expression:"order_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}],null,false,3298296331)}):_vm._e(),_c('validation-provider',{attrs:{"name":"Product ID","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Product ID"}},[_c('v-select',{staticClass:"v-select",attrs:{"options":_vm.rows.map(function (row) { return ({
                  label: ("[" + (row.id) + "] - [" + (row.product_name) + "]"),
                  value: row.id,
                }); }),"label":"label","placeholder":"Select a Product","reduce":function (row) { return row.value; }},model:{value:(_vm.product_id),callback:function ($$v) {_vm.product_id=$$v},expression:"product_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"Quantity","rules":"required|positive"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Quantity"}},[_c('b-form-input',{attrs:{"type":"number","placeholder":"Enter Quantity"},model:{value:(_vm.quantity),callback:function ($$v) {_vm.quantity=$$v},expression:"quantity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"Warehouse Location","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Warehouse Location"}},[_c('v-select',{staticClass:"v-select",attrs:{"options":_vm.branches,"label":"warehouse","placeholder":"Select a Warehouse Location","reduce":function (branch) { return branch.warehouse_id; }},model:{value:(_vm.warehouse_id),callback:function ($$v) {_vm.warehouse_id=$$v},expression:"warehouse_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('validation-provider',{attrs:{"name":"Remarks","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Remarks"}},[_c('b-form-textarea',{attrs:{"placeholder":"Enter Remarks"},model:{value:(_vm.remarks),callback:function ($$v) {_vm.remarks=$$v},expression:"remarks"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])}),_c('b-row',{staticClass:"justify-content-center"},[_c('b-col',{attrs:{"cols":"auto"}},[_c('b-button',{attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.$bvModal.hide('record-stock-modal')}}},[_vm._v("Cancel")])],1),_c('b-col',{attrs:{"cols":"auto"}},[_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.submitStock()}}},[_vm._v("Submit")])],1)],1)],1)],1)],1),_c('b-modal',{attrs:{"id":"low-stock-modal","hide-header":"","hide-footer":""}},[_c('h3',{staticClass:"text-primary my-1"},[_vm._v("Low Stock Products")]),_c('ul',{staticClass:"list-group my-1"},_vm._l((_vm.lowStockProducts),function(product){return _c('li',{key:product.id,staticClass:"list-group-item d-flex justify-content-between align-items-center"},[_c('span',[_vm._v(_vm._s(product.product_name))]),_vm._v(" - "),_c('span',[_vm._v("Only "),_c('b',{staticClass:"text-primary"},[_vm._v(_vm._s(product.remaining_stock))]),_vm._v(" left!")])])}),0)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }