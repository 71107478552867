<template>
  <b-container fluid>
    <div class="d-flex justify-content-between">
      <div class="d-flex align-items-center">
        <h4 class="mr-2">Inventory Summary</h4>
      </div>
      <div class="d-flex align-items-center">
        <b-button
          variant="dark"
          class="mr-2 my-1"
          :to="{ path: '/inventory/create' }"
        >
          <feather-icon icon="PlusIcon" size="16" class="mr-25"></feather-icon>
          Add a New Product
        </b-button>
        <b-button variant="primary" v-b-modal.record-stock-modal>
          <feather-icon icon="EditIcon" size="16"></feather-icon>
          Record Stock
        </b-button>
      </div>
    </div>
    <b-row>
      <b-col cols="6">
        <b-card bg-variant="primary" text-variant="white">
          <b-row>
            <b-col>
              <h5 class="text-white">All Products</h5>
            </b-col>
            <b-col>
              <h5 class="text-white">Lowstock alert</h5>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <p>
                <strong>{{ totalProducts }}</strong>
              </p>
            </b-col>
            <b-col>
              <p>
                <b-link
                  v-b-modal.low-stock-modal
                  class="text-decoration-underline text-white"
                  style="text-decoration: underline"
                >
                  <strong>{{ lowStockProducts.length }}</strong>
                </b-link>
              </p>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="6">
        <!-- <b-card>
          <b-row>
            <b-col>
              <h5>In</h5>
            </b-col>
            <b-col>
              <h5>Out</h5>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col>
              <p>
                <strong>{{ totalProducts }}</strong>
              </p>
            </b-col>
            <b-col>
              <p>
                <strong>{{ lowStockProducts }}</strong>
              </p>
            </b-col>
          </b-row>
        </b-card> -->
      </b-col>
    </b-row>
    <b-card>
      <MyTable
        :columns="columns"
        :rows="rows"
        :isLoading="isLoading"
        :checkboxEnabled="true"
        @deleteItems="deleteItems"
        :inventory="true"
      >
        <template v-slot="{ props }">
          <div v-if="props.column.field === 'image'">
            <div
              v-if="
                props.row.product_images && props.row.product_images.length > 0
              "
            >
              <img
                :src="props.row.product_images[0].image_path"
                class="img-thumbnail"
                alt="Product Image"
                style="width: 160px; height: 130px"
              />
            </div>
            <div v-else>No image available</div>
          </div>
          <div v-else-if="props.column.field === 'sellingPrice'">
            <span>{{ $helpers.formatTotal(props.row.selling_price) }}</span>
          </div>
          <div v-else-if="props.column.field === 'totalValue'">
            <span>{{ $helpers.formatTotal(props.row.total_value) }}</span>
          </div>
          <div
            v-else-if="props.column.field === 'action'"
            class="d-flex justify-content-center"
          >
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                :to="{
                  name: 'inventory-product-detail',
                  params: { id: props.row.id },
                }"
              >
                <span>Details</span>
              </b-dropdown-item>
              <b-dropdown-item
                :to="{
                  name: 'inventory-edit-product',
                  params: { id: props.row.id },
                }"
              >
                <span>Edit</span>
              </b-dropdown-item>
              <b-dropdown-item @click="deleteItem(props.row)">
                <span>Delete</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </template>
      </MyTable>
    </b-card>
    <b-modal
      id="record-stock-modal"
      hide-header
      hide-footer
      no-close-on-backdrop
    >
      <validation-observer ref="simpleRules">
        <b-form>
          <!-- Radio Buttons -->
          <validation-provider
            name="Transaction Type"
            rules="required"
            v-slot="{ errors }"
          >
            <b-form-group label="Transaction Type">
              <b-form-radio-group
                v-model="type"
                name="type"
                buttons
                button-variant="primary"
                class="w-100"
              >
                <b-form-radio value="1" class="w-50">In</b-form-radio>
                <b-form-radio value="0" class="w-50">Out</b-form-radio>
              </b-form-radio-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Date Added -->
          <validation-provider
            name="Date Added"
            rules="required"
            v-slot="{ errors }"
          >
            <b-form-group label="Date Added">
              <flat-pickr
                v-model="date_added"
                class="form-control"
                placeholder="Select Date"
              ></flat-pickr>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Order ID -->
          <validation-provider
            name="Order ID"
            rules="required"
            v-if="type === '0'"
            v-slot="{ errors }"
          >
            <b-form-group label="Order ID">
              <v-select
                v-model="order_id"
                :options="options"
                :filterable="false"
                :reduce="(order) => order.id"
                @search="onSearch"
                placeholder="Enter Order ID"
                label="id"
              ></v-select>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Product ID -->
          <validation-provider
            name="Product ID"
            rules="required"
            v-slot="{ errors }"
          >
            <b-form-group label="Product ID">
              <v-select
                v-model="product_id"
                :options="
                  rows.map((row) => ({
                    label: `[${row.id}] - [${row.product_name}]`,
                    value: row.id,
                  }))
                "
                label="label"
                placeholder="Select a Product"
                class="v-select"
                :reduce="(row) => row.value"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Quantity -->
          <validation-provider
            name="Quantity"
            rules="required|positive"
            v-slot="{ errors }"
          >
            <b-form-group label="Quantity">
              <b-form-input
                v-model="quantity"
                type="number"
                placeholder="Enter Quantity"
              ></b-form-input>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Warehouse Location -->
          <validation-provider
            name="Warehouse Location"
            rules="required"
            v-slot="{ errors }"
          >
            <b-form-group label="Warehouse Location">
              <v-select
                v-model="warehouse_id"
                :options="branches"
                label="warehouse"
                placeholder="Select a Warehouse Location"
                class="v-select"
                :reduce="(branch) => branch.warehouse_id"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Remarks -->
          <validation-provider
            name="Remarks"
            rules="required"
            v-slot="{ errors }"
          >
            <b-form-group label="Remarks">
              <b-form-textarea
                v-model="remarks"
                placeholder="Enter Remarks"
              ></b-form-textarea>
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Buttons -->
          <b-row class="justify-content-center">
            <b-col cols="auto">
              <b-button
                variant="secondary"
                @click="$bvModal.hide('record-stock-modal')"
                >Cancel</b-button
              >
            </b-col>
            <b-col cols="auto">
              <b-button variant="primary" @click="submitStock()"
                >Submit</b-button
              >
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
    <b-modal id="low-stock-modal" hide-header hide-footer>
      <!-- Low Stock Products List -->
      <h3 class="text-primary my-1">Low Stock Products</h3>
      <ul class="list-group my-1">
        <li
          v-for="product in lowStockProducts"
          :key="product.id"
          class="list-group-item d-flex justify-content-between align-items-center"
        >
          <span>{{ product.product_name }}</span>
          -
          <span
            >Only
            <b class="text-primary">{{ product.remaining_stock }}</b>
            left!</span
          >
        </li>
      </ul>
    </b-modal>
  </b-container>
</template>
<script>
import flatPickr from "vue-flatpickr-component";
import MyTable from "@/views/components/MyTable.vue";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import debounce from "lodash/debounce";

export default {
  components: {
    MyTable,
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    vSelect,
    FeatherIcon,
  },

  data() {
    return {
      type: "1",
      date_added: "",
      order_id: "",
      product_id: "",
      quantity: "",
      warehouse_id: "",
      remarks: "",
      options: [],
      columns: [
        {
          label: "Product Image",
          field: "image",
          tdClass: "text-center align-middle",
        },
        {
          label: "Product Name",
          field: "product_name",
          tdClass: "text-center align-middle",
        },
        {
          label: "Category",
          field: "product_category",
          tdClass: "text-center align-middle",
        },
        {
          label: "Selling Price",
          field: "sellingPrice",
          tdClass: "text-center align-middle",
        },
        {
          label: "In-Stock(Carton)",
          field: "remaining_stock",
          tdClass: "text-center align-middle",
        },
        {
          label: "Total Value",
          field: "totalValue",
          tdClass: "text-center align-middle",
        },
        {
          label: "Action",
          field: "action",
          tdClass: "text-center align-middle",
        },
      ],
      rows: [],
      required,
      branches: [],
      warehouseLoc: [],
      lowStockProducts: [],
    };
  },

  created() {
    this.fetchProducts();
  },

  watch: {
    product_id(newProductId) {
      this.updateWarehouseOptions(newProductId);
    },
  },

  computed: {
    totalProducts() {
      return this.rows.length;
    },
  },

  methods: {
    updateWarehouseOptions(productId) {
      const selectedRow = this.rows.find((row) => row.id === productId);
      this.branches = selectedRow.warehouse;
    },
    async submitStock() {
      this.$refs.simpleRules.validate().then(async (success) => {
        if (success) {
          try {
            const response = await this.$http.post(
              "/inventory/admin/product_inventory_adjustment",
              {
                type: this.type,
                date_added: this.date_added,
                order_id: this.order_id,
                product_id: this.product_id,
                quantity: this.quantity,
                warehouse_id: String(this.warehouse_id),
                remarks: this.remarks,
              }
            );

            // Check the response message and display appropriate toast notification
            if (
              response.data.message ===
              "Quantity must be less than current quantity"
            ) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "AlertTriangleIcon",
                  variant: "danger",
                  text: response.data.message,
                },
              });
            } else {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "CheckIcon",
                  variant: "success",
                  text: response.data.message,
                },
              });
              this.fetchProducts();
              this.$bvModal.hide("record-stock-modal");
            }
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "XIcon",
                variant: "danger",
                text: "Failed to record stock",
              },
            });
          }
        } else {
          console.error("Validation failed");
          // Handle validation failure (e.g., show error message)
        }
      });
    },
    async fetchProducts() {
      this.isLoading = true;
      try {
        const response = await this.$http.get(
          "inventory/admin/products?page=1"
        );
        if (response.status === 200 && response.data.data?.length > 0) {
          this.rows = response.data.data.map((row) => ({
            ...row,
            total_value: row.selling_price * row.remaining_stock,
          }));
          this.lowStockProducts = this.rows.filter(
            (product) => product.remaining_stock < 11
          );
        } else {
          this.rows = [];
        }
      } catch (error) {
        console.error("Error fetching products:", error);
        this.rows = [];
      } finally {
        this.isLoading = false;
      }
    },
    async deleteItem(row) {
      try {
        const response = await this.$http.delete(
          `inventory/admin/products/${row.id}`
        );
        if (response.status === 200) {
          // Handle successful deletion
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Success",
              icon: "CheckIcon",
              variant: "success",
              text: "Deleted successfully",
            },
          });
          // Fetch updated products list
          this.fetchProducts();
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Success",
              icon: "XIcon",
              variant: "warning",
              text: "Delete failed",
            },
          });
        }
      } catch (error) {
        console.error("Error deleting item:", error);
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error,
            icon: "CheckIcon",
            variant: "success",
          },
        });
      }
    },
    async deleteItems(selectedIds) {
      try {
        const ids = selectedIds.join(",");
        const response = await this.$http.get(
          `/inventory/admin/batch_remove_products/${ids}`
        );
        if (response.status === 200) {
          // Handle successful deletion
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Success",
              icon: "CheckIcon",
              variant: "success",
              text: "Deleted successfully",
            },
          });
          // Fetch updated products list
          this.fetchProducts();
        } else {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Success",
              icon: "XIcon",
              variant: "warning",
              text: "Delete failed",
            },
          });
        }
      } catch (error) {
        console.error("Error deleting items:", error);
      }
    },
    async onSearch(search) {
      if (search) {
        this.debouncedSearch(search);
      }
    },
    async searchOrder(search) {
      try {
        const response = await this.$http.post(`inventory/admin/search_order`, {
          search: search,
        });
        this.options = response.data.data;
      } catch (error) {
        console.error("Error fetching order data:", error);
        this.errors = ["Error fetching order data."];
      }
    },
    debouncedSearch: debounce(function (search) {
      this.searchOrder(search);
    }, 1000),
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
